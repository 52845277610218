<template>
  <v-overlay z-index="9999" :value="mainSpinner">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  computed: {
    mainSpinner () {
      return this.$root.spinners.main
    }
  }

}
</script>

<style lang="scss" scoped>
</style>
